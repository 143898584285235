.unit-image-gallery-v1 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;

	.visible-print {
		display: none !important;
	}

	.unitGallery {
		.matterport-div {
			height: 540px;
			position: relative;
			padding: 5px;

			@media (max-width:767px) {
				height: 400px;
			}

			iframe {

				position: absolute;
				width: calc(100% - 10px);
				height: calc(100% - 5px);
				top: 0;
			}
		}

		a {
			display: none;
			padding: 5px;
			height: 150px;

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 100%;
				float: right;
				display: block;
				height: 540px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 400px;
				}
			}

			&:nth-child(2) {
				clear: both;

				@media (max-width: 767px) {
					clear: none;
					height: 140px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				display: block;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(6) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}


		}
	}

}