.newsletter-form {
	font-family: "Helvetica Neue", Arial, sans-serif;
	padding-right: 15px;

	label {
		color: #000;
		font-size: 16px
	}

	.form-control {
		border-radius: 0px;
		box-shadow: 0 0 0 1px transparent;
		background-color: #fafafa;
		border: 1px solid #a9a9a9;
		height: 50px;

		&:hover {
			background-color: #fafafa;
		}

	}


	.btn-newsletter {
		font-size: 16px;
		height: 50px;
		padding: 0 25px;
		background-color: #305D4C;

		&:hover {
			background-color: #597d70;
			border: 1px solid #597d70;
			color: #fff;
		}

	}

	.required {
		color: #4c4c4c;
		padding-left: 5px;
	}
}